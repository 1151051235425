import React from 'react';
import './HomePage.css';

const HomePage = () => {
  return (
    <div className="HomePage">
      <div className="content">
        <h1>Hi, I'm Nick</h1>
        <div className="bio">
          <img src="/images/profile.jpg" alt="John Smith" className="headshot" />
          <p>
            I build and implement computational tools for social good.
            I graduated from the University of Oxford with master's degrees in Mathematics
            and Linguistics, where I studied
            as a Rhodes Scholar. Prior to Oxford, I earned degrees in Mathematics and German from the 
            University of Alabama, with some side quests into physics, psychology, and computer science. 
            <br></br>
            <br></br>
            "Social Good" is a big umbrella, which is awesome news for my diverse interests. 
            Alongside academic endeavors, I have soft spots for 
            backcountry camping, dolphins, the mandolin, trail-running, puzzle cubes, and competitive yoyo-ing. Which is
            precisely why, if there weren't more of The Good Work &trade; to be done in the world, I would be hiding out in remote
            corners of the world, going on trail-runs and learning languages (which I still do during time off).
            <br></br>
            <br></br>
            I'd love to hear from you if anything piques your interest. 
            <br></br>
            <br></br>
          </p>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
