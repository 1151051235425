import React from 'react';
import './WritingPage.css';

const WritingPage = () => {
  return (
    <div className="writing-container">
      <div className="article">
        <h2 className="article-title">Article Title 1</h2>
        <p className="article-description">Brief description of Article 1</p>
        <a href="#" className="read-more-link">Read more</a>
      </div>

      <div className="article">
        <h2 className="article-title">Article Title 2</h2>
        <p className="article-description">Brief description of Article 2</p>
        <a href="#" className="read-more-link">Read more</a>
      </div>

      <div className="article">
        <h2 className="article-title">Article Title 3</h2>
        <p className="article-description">Brief description of Article 3</p>
        <a href="#" className="read-more-link">Read more</a>
      </div>

      <div className="article">
        <h2 className="article-title">Article Title 4</h2>
        <p className="article-description">Brief description of Article 4</p>
        <a href="#" className="read-more-link">Read more</a>
      </div>
    </div>
  );
};

export default WritingPage;
