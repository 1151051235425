import React from 'react';
import './BookshelfPage.css';

const books = [
  {
    title: 'Homegoing',
    author: "Yaa Gyasi",
    description: 'Gifted to me by an amazing friend. Heartbreaking storytelling.',
  },
  {
    title: 'The Kite Runner',
    author: "Khaled Hosseini",
    description: 'My mom told me this was a must-read. She was absolutely right (thanks mom).',
  },
  {
    title: 'Time is a Mother',
    author: "Ocean Vuong",
    description: "Awesome poetry collection. Go for On Earth We're Briefly Gorgeous if you don't love poetry. Either way, read Ocean Vuong.",
  },
  {
    title: 'Before the Coffee Gets Cold',
    author: "Toshikazu Kawaguchi",
    description: "For your short story fix. Less sci-fi than Ted Chiang's work, but equally thought-provoking.",
  },
  {
    title: 'The Art of Language Invention',
    author: "David Peterson",
    description: 'An accessible intro to pop linguistics from the creator of Dothraki himself.',
  },  
];

const websites = [
  {
    title: 'Our World in Data',
    url: 'https://ourworldindata.org/',
    description: "An easy way to learn (or at least visualize) something new.",
  },
  {
    title: 'AllSides',
    url: 'https://www.allsides.com/unbiased-balanced-news',
    description: "Keeping up with news headlines across the US political spectrum.",
  },
  {
    title: 'Counting the Kids',
    url: 'https://countingthekids.org/',
    description: 'Humans already have trouble visualizing large numbers. Harrowing when it comes to human lives.',
  },
  {
    title: '3D Portfolio',
    url: 'https://bruno-simon.com/',
    description: "A fun way to go for a drive.",
  },
  {
    title: 'Neal.Fun',
    url: 'https://neal.fun/',
    description: "More ways to procrastinate.",
  },
  {
    title: 'Gwern',
    url: 'https://gwern.net/',
    description: "Genuinely no idea how to succintly summarize Gwern. Check it out.",
  },
];

const BookshelfPage = () => {
  return (
    <div className="bookshelf">
      <p className="intro-paragraph">
        Some of my favorite books, websites, and more.
      </p>
      <div className="section">
        <h2 className="section-label books-label">Books</h2>
        <div className="card-container">
          {books.map((book, index) => (
            <div key={index} className="card">
              <h3 className="card-title">{book.title}</h3>
              <p className="card-author">{book.author}</p>
              <p className="card-description">{book.description}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="section">
        <h2 className="section-label websites-label">Websites</h2>
        <div className="card-container">
          {websites.map((website, index) => (
            <div key={index} className="card">
              <a 
                href={website.url} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="card-title card-link"
              >
                {website.title}
              </a>
              <p className="card-description">{website.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BookshelfPage;
