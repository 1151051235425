import React from 'react';
import NetworkGraph from '../components/NetworkGraph';
import './AcademicPage.css';

const AcademicPage = () => {
  const work = [
    {
      title: 'Topology Mediated Network Turing Patterns',
      authors: ['Nicholas Hayes'],
      venue: 'TBD',
      year: '2024',
      description: 'In progress. Extension of my dissertation.',
      link: './pdfs/dissertation.pdf',
    },
    {
      title: 'Monotonous Monotonicity: Activation Functions in Graph Attention Mechanisms',
      authors: ['Nicholas Hayes'],
      venue: 'TBD',
      year: '2023',
      description: 'In progress. Extension of a course paper.',
      link: './pdfs/tdl.pdf',
    },
    {
      title: 'A diachronic corpus-assisted semantic domain analysis of US presidential debates',
      authors: ['Nicholas Hayes', 'Robert Poole'],
      venue: 'Corpora',
      year: '2022',
      link: '/pdfs/pub1_debates.pdf',
    },
    {
      title: 'Stance in climate science: A diachronic analysis of epistemic stance features in IPCC physical science reports',
      authors: ['Robert Poole, Nicholas Hayes'],
      venue: 'Journal of Corpora and Discourse Studies',
      year: '2022',
      link: '/pdfs/pub2_climate_stance.pdf',
    },
    {
      title: 'Trump talk - is it really anomalous?',
      authors: ['Nicholas Hayes'],
      venue: 'AAAL',
      year: '2022',
      description: 'Conference presentation',
    },
    {
      title: 'Who’s talking now? Automatically classifying dolphin species using passive acoustics.',
      authors: ['Nicholas Hayes'],
      venue: 'NOAA Hollings Symposium',
      year: '2021',
      description: 'Conference presentation'
    },
  ];

  return (
    <div className="academic-page">
      <div className="section education-section justify-text">
        <h2 className="section-label">Education</h2>
        <p className="section-description">
          I hold an MSc in Mathematical Sciences and MSt in Linguistics, Philology, and Phonetics from the
          University of Oxford. I earned undergraduate degrees in Applied Mathematics and Foreign Languages
          and Literature (German) from the University of Alabama. I also spent an academic year in Tanzania,
          where I earned a fluency certificate in Swahili.
        </p>
        <br />
        <p>
          My most rewarding learning experiences have not happened in a classroom, but for a select list of
          things I have studied in a more formal capacity, please click <a href="/Courses"> here</a>.
        </p>
      </div>

    <div className="section research-interests-section">
    <h2 className="section-label">Interests Network</h2>
    <p> Click, drag, and zoom to explore.</p>
    <br></br>
    <div className="network-container"> {/* Added container for the network graph */}
        <NetworkGraph />
    </div>
    </div>

      <div className="section work-section left-align-text">
        <h2 className="section-label">My Work</h2>
        <p>Selected pieces I have either written or presented in a formal capacity.
          See the Writing section for a broader list of academic, personal, and creative work.
        </p>
        <br />
        {work.map((item, index) => (
        <div key={index} className="work-entry item">
        {item.link ? (
          <h3 className="item-title">
            <a href={item.link} target="_blank" className="item-link">{item.title}</a>
          </h3>
        ) : (
          <h3 className="item-title">{item.title}</h3>
        )}
        <p className="item-description">{item.description}</p>
        <p className="item-authors">{item.authors.join(', ')}</p>
        <p className="item-venue">{item.venue}, {item.year}</p>
      </div>
      
        ))}

      </div>
    </div>
  );
};

export default AcademicPage;
