import React, { useEffect, useRef } from 'react';
import { Network } from 'vis-network';

const NetworkGraph = () => {
  const networkRef = useRef(null);

  useEffect(() => {
    const nodes = [
      { id: 1, label: 'Linguistics' , value:100},
      { id: 2, label: 'Mathematics' , value:100},
      { id: 3, label: 'AI for Social Good' , value:100},
      { id: 4, label: 'Climate' , value:50},
      { id: 5, label: 'Computational Neuroscience' , value:1},
      { id: 6, label: 'Swahili' , value:1},
      { id: 7, label: 'Historical Linguistics' , value:1},
      { id: 8, label: 'Low-Resource Machine Translation' , value:1},
      { id: 9, label: 'Language Preservation' , value:1},
      { id: 10, label: 'Germanic Languages' , value:1},
      { id: 11, label: 'Network Turing Patterns' , value:1},
      { id: 12, label: 'Hyperbolic Geometry' , value:1},
      { id: 13, label: 'Synthetic Data ' , value:1},
      { id: 14, label: 'Activation Function Design' , value:1},
      { id: 15, label: 'Hyperbolic Neural Nets' , value:1},
      { id: 16, label: 'East African Climate Paradox' , value:1},
      { id: 17, label: 'Adversarial Extraction Attacks' , value:1},
      { id: 18, label: 'Orthography' , value:1},
      { id: 19, label: 'Carbon Buffering Models' , value:1},
      { id: 20, label: 'Human Feedback Reinforcement Learning' , value:1},
      { id: 22, label: 'AI Safety' , value:50},
      { id: 23, label: 'Nuclear Energy' , value:1}

      // Add more nodes here
    ];

    const edges = [
      { from: 1, to: 3 },
      { from: 1, to: 5 },
      { from: 1, to: 6 },
      { from: 1, to: 7 },
      { from: 1, to: 8 },
      { from: 1, to: 9 },
      { from: 1, to: 10 },
      { from: 2, to: 3 },
      { from: 2, to: 5 },
      { from: 2, to: 12 },
      { from: 2, to: 4 },
      { from: 3, to: 4 },
      { from: 3, to: 8 },
      { from: 3, to: 22 },
      { from: 4, to: 16 },
      { from: 4, to: 19 },
      { from: 4, to: 23 },
      { from: 5, to: 3 },
      { from: 5, to: 14 },
      { from: 6, to: 7 },
      { from: 6, to: 16 },
      { from: 7, to: 18 },
      { from: 8, to: 9 },
      { from: 8, to: 13 },
      { from: 11, to: 2 },
      { from: 11, to: 4 },
      { from: 11, to: 5 },
      { from: 12, to: 15 },
      { from: 14, to: 15 },
      { from: 17, to: 22 },
      { from: 17, to: 13 },
      { from: 20, to: 22 },
      { from: 20, to: 3 },

      // Add more edges here
    ];

    const container = networkRef.current;
    const data = { nodes, edges };
    const options = {
      nodes: {
        shape: 'dot',
        size: 50,
        font: {
          size: 16,
          color: '#333333',
        },
        borderWidth: 1,
        color: {
          background: '#60B3F7', // Fill color
          border: '#333333' // Outline color
        }
      },
      edges: {
        color: '#333333',
      },
      physics: {
        enabled: false,
      },
        
    interaction: {
      hover: true,
    },
      layout: {
        improvedLayout: true,
        randomSeed: 23,
      }
    };

    new Network(container, data, options);
  }, []);

  return <div ref={networkRef} style={{ height: '500px' }} />;
};

export default NetworkGraph;
