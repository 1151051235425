import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <AppBar position="static" sx={{ bgcolor: '#FFFAF0', boxShadow: 'none', fontFamily: 'Lato, sans-serif' }}>
      <Toolbar sx={{ justifyContent: 'center' }}>
        <IconButton
          size="large"
          edge="start"
          aria-label="menu"
          sx={{ display: { md: 'none' }, position: 'absolute', left: 16 }}
          onClick={toggleMobileMenu}
        >
          <MenuIcon />
        </IconButton>
        <List sx={{ display: { xs: 'none', md: 'flex' }, gap: 2 }}>
          {['Home', 'Experience', /*'Writing',*/ 'Academic', 'Bookshelf', 'Contact'].map((text) => (
            <ListItem
              button
              key={text}
              component={Link}
              to={`/${text.toLowerCase()}`}
              sx={{
                color: '#333333',
                transition: 'background-color 0.3s, color 0.3s',
                borderRadius: '8px', // Set the initial border radius
                '&:hover': {
                  backgroundColor: '#662B36',
                  color: '#FFF',
                },
              }}
            >
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Toolbar>
      <Drawer
        anchor="left"
        open={isMobileMenuOpen}
        onClose={toggleMobileMenu}
      >
        <List>
          {['Home', 'Experience', /*'Writing',*/ 'Academic', 'Bookshelf', 'Contact'].map((text) => (
            <ListItem
              button
              key={text}
              component={Link}
              to={`/${text.toLowerCase()}`}
              onClick={toggleMobileMenu}
              sx={{
                color: '#333333',
                transition: 'background-color 0.3s, color 0.3s',
                borderRadius: '8px', // Set the initial border radius
                '&:hover': {
                  backgroundColor: '#662B36',
                  color: '#FFF',
                },
              }}
            >
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </AppBar>
  );
};

export default Header;
