import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const ExperiencePage = () => {
  return (
    <div className="experience-container">
      
      <VerticalTimeline
      >
        {/* Entry 1 */}
        <VerticalTimelineElement
          className="vertical-timeline-element"
          contentStyle={{ background: '#FFFAF0', color: '#333333' }}
          contentArrowStyle={{ borderRight: '7px solid  #333333' }}
          date=""
          iconStyle={{ background: '#662B36', color: '#fff', border: "662B36"}}
          icon={<i className="fa fa-briefcase"></i>}
          >
          <h3 className="vertical-timeline-element-title">The Center for Advanced Defense Studies</h3>
          <h4 className="vertical-timeline-element-subtitle">Data Scientist</h4>
          <p>Hired as an intern and then contract data scientist. 
            Became a swiss-army knife for ML, NLP, and web-scraping tasks. 
            Worked broadly on anti-trafficking (wildlife, drugs, humans, etc.)
            Highlights include building a geolocation pipeline for Vietnamese addresses (anti-pangolin trafficking), 
            finetuning an object recognition model with synthetic aperture radar data, automating a linguistic analysis of
            political speeches in Mandarin, and fighting with Selenium. 
            <br></br>
            <br></br>
            Tech Stack: Python, AWS, GCP
          </p>
        </VerticalTimelineElement>

        {/* Entry 2 */}
        <VerticalTimelineElement
          className="vertical-timeline-element"
          contentStyle={{ background: '#FFFAF0', color: '#333333' }}
          contentArrowStyle={{ borderRight: '7px solid  #333333' }}
          date=""
          iconStyle={{ background: '#662B36', color: '#662B36' }}
          icon={<i className="fa fa-briefcase"></i>}
        >
          <h3 className="vertical-timeline-element-title">NOAA (Northeast Fisheries Science Center)</h3>
          <h4 className="vertical-timeline-element-subtitle">Machine Learning Research Intern</h4>
          <p>Created a classification model for 9 cetacean species in the Western North Atlantic
            using passive acoustic recordings. Spent my mornings listening to dolphin clicks and
            staring at spectrograms until my eyes hurt, spent my afternoons playing around with random
            forest models. Learned how to identify Risso's dolphin by ear. 
            <br></br>
            <br></br>
            Tech stack: PAMGuard, R 
            </p>
        </VerticalTimelineElement>

        {/* Entry 3 */}
        <VerticalTimelineElement
          className="vertical-timeline-element"
          contentStyle={{ background: '#FFFAF0', color: '#333333' }}
          contentArrowStyle={{ borderRight: '7px solid  #333333' }}
          date=""
          iconStyle={{ background: '#662B36', color: '#662B36' }}
          icon={<i className="fa fa-briefcase"></i>}
        >
          <h3 className="vertical-timeline-element-title">Response Crises Translations</h3>
          <h4 className="vertical-timeline-element-subtitle">Swahili Translator</h4>
          <p>Translated an 85 page legal document from English to Swahili for
            Lawyers without Borders. Ongoing pro-bono translations as needed.
          </p>
        </VerticalTimelineElement>

        {/* Entry 4 */}
        <VerticalTimelineElement
          className="vertical-timeline-element"
          contentStyle={{ background: '#FFFAF0', color: '#333333' }}
          contentArrowStyle={{ borderRight: '7px solid  #333333' }}
          date=""
          iconStyle={{ background: '#662B36', color: '#662B36' }}
          icon={<i className="fa fa-briefcase"></i>}
        >
          <h3 className="vertical-timeline-element-title">US Department of State</h3>
          <h4 className="vertical-timeline-element-subtitle">Data Science Intern</h4>
          <p>Built an annotated dataset of research papers for downstream summarization tasks.
            Automated my work using simple NER and summarization techniques, spent the saved 
            time teaching myself web development.
            <br></br>
            <br></br>
            Tech Stack: Python
          </p>
        </VerticalTimelineElement>

        {/* Entry 5 */}
        <VerticalTimelineElement
          className="vertical-timeline-element"
          contentStyle={{ background: '#FFFAF0', color: '#333333' }}
          contentArrowStyle={{ borderRight: '7px solid  #333333' }}
          date=""
          iconStyle={{ background: '#662B36', color: '#662B36' }}
          icon={<i className="fa fa-briefcase"></i>}
        >
          <h3 className="vertical-timeline-element-title">Protagonist</h3>
          <h4 className="vertical-timeline-element-subtitle">Swahili Consultant</h4>
          <p>Contracted to translate search queries and evaluate machine translation accuracy
            of Tanzanian media. My favorite mistranslation I found: "uwanja wa ndege" (airport)
            translated as "field of birds."</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element"
          contentStyle={{ background: '#FFFAF0', color: '#333333' }}
          contentArrowStyle={{ borderRight: '7px solid  #333333' }}
          date=""
          iconStyle={{ background: '#662B36', color: '#662B36' }}
          icon={<i className="fa fa-briefcase"></i>}
        >
          <h3 className="vertical-timeline-element-title">DORCAS</h3>
          <h4 className="vertical-timeline-element-subtitle">Project Management Intern</h4>
          <p>Mostly translated grant proposals (Swahili/English/Dutch) and conducted
            field interviews with program beneficiaries in Swahili. Drank copious
            amounts of tea and never learned how not to burn my tongue. 
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element"
          contentStyle={{ background: '#FFFAF0', color: '#333333' }}
          contentArrowStyle={{ borderRight: '7px solid  #333333' }}
          date=""
          iconStyle={{ background: '#662B36', color: '#662B36' }}
          icon={<i className="fa fa-briefcase"></i>}
        >
          <h3 className="vertical-timeline-element-title">The University of Alabama</h3>
          <h4 className="vertical-timeline-element-subtitle">Teaching Assistant, Physics</h4>
          <p>Led lab sessions for introductory physics (Mechanics, Electricity and Magnetism). 
            Still have no idea how a bicycle stays upright (but neither does anyone else).
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
};

export default ExperiencePage;