import React from 'react';
import './ContactPage.css';

const ContactPage = () => {
  return (
    <div className="contact-page">
      <p className="contact-text">nickphayes dot one four at gmail dot com</p>
    </div>
  );
};

export default ContactPage;
