import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ExperiencePage from './pages/ExperiencePage';
import BookshelfPage from './pages/BookshelfPage';
import WritingPage from './pages/WritingPage';
import AcademicPage from './pages/AcademicPage';
import CoursesPage from './pages/CoursesPage';
import ContactPage from './pages/ContactPage';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';

import ReactGA from 'react-ga';

function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize('G-C0TCSKVRKB'); 
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    const currentPath = location.pathname + location.search;
    ReactGA.set({ page: currentPath });
    ReactGA.pageview(currentPath);
  }, [location]);

  return (
    <div className="App">
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/Home" element={<HomePage />} />
          <Route path="/Experience" element={<ExperiencePage />} />
          <Route path="/Bookshelf" element={<BookshelfPage />} />
          <Route path="/Writing" element={<WritingPage />} />
          <Route path="/Academic" element={<AcademicPage />} />
          <Route path="/Courses" element={<CoursesPage />} />
          <Route path="/Contact" element={<ContactPage />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
