import React from 'react';
import './CoursesPage.css';

const CoursesPage = () => {
  // Define data for each subject
  const subjectsData = [
    {
      title: "Mathematics",
      courses: ["Perturbation Methods", "Continuous Optimization", "Mathematical Geoscience"
        , "Random Matrix Theory (audit)", "Networks", "Theories of Deep Learning"
        , "Numerical Linear Algebra", "Numerical Analysis", "Computational Biology"
        , "Computational Neuroscience (audit)", "Theory of Probability"
        , "Complex Variables", "Discrete Mathematics"
      ]
    },
    {
      title: "Linguistics",
      courses: ["Linguistic Anthropology", "Semantics", "Phonology"
        , "Phonetics", "Computational Linguistics"
        , "NLP for Social Data Science", "Historical/Comparative Linguistics"
        , "Sociolinguistics", "Syntax", "Phonology"
      ]
    },
  ];

  return (
    <div className="courses-container">
      {/* Map over subjectsData to render each subject */}
      {subjectsData.map((subject, index) => (
        <div className="subject" key={index}>
          <h2>{subject.title}</h2>
          <ul>
            {/* Map over courses in each subject to render list items */}
            {subject.courses.map((course, idx) => (
              <li key={idx}>{course}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export default CoursesPage;
